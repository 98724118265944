<template>
<div>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <b-container>
            <b-row align-h="center">
                <b-col md="12">
                    <b-form @submit.prevent="addUser">
                        <b-row class="bg-white shadow p-5">
                            <b-col md="12" class="mb-4">
                                <b-form-group id="input-group-1" label="First Name:" label-for="input-1">
                                    <b-form-input id="input-1" v-model="form.fname" type="text" placeholder="Enter First Name">
                                    </b-form-input>
                                </b-form-group>
                                <div class="error" v-if="submitted && !$v.form.fname.required">
                                    First name is required
                                </div>
                                <div class="error" v-if="submitted && !$v.form.fname.minLength">
                                    First name minimum length is 3
                                </div>
                                <div class="error" v-if="submitted && !$v.form.fname.maxLength">
                                    First name maximum length is 20
                                </div>
                            </b-col>

                            <b-col md="12" class="mb-4">
                                <b-form-group id="input-group-1" label="Last Name:" label-for="input-1">
                                    <b-form-input id="input-1" v-model="form.lname" type="text" placeholder="Enter Last name">
                                    </b-form-input>
                                </b-form-group>
                                <!-- <div class="error" v-if="submitted && !$v.form.lname.required">
                                    Last name is required
                                </div> -->
                            </b-col>

                            <!-- Username  -->

                            <b-col md="12" class="mb-4">
                                <b-form-group id="input-group-1" label="Email address:" label-for="input-2">
                                    <b-form-input id="input-2" v-model="form.email" type="email" placeholder="Enter Email">
                                    </b-form-input>
                                </b-form-group>
                                <div class="error" v-if="submitted && !$v.form.email.required">
                                    Email is required
                                </div>
                                <div class="error" v-if="submitted && !$v.form.email.email">
                                    This must be an email
                                </div>
                            </b-col>
                            <!-- Email  -->

                            <b-col md="12" class="mb-4" id="input-number-field">
                                <b-form-group id="input-group-1" label="Mobile:" label-for="input-mobile">
                                    <b-form-input id="input-mobile" v-model="form.mobile" type="text" placeholder="Enter Mobile Number" onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))">
                                    </b-form-input>
                                </b-form-group>

                                <div class="error" v-if="submitted && !$v.form.mobile.minLength">
                                    Mobile minimum length is 10
                                </div>
                                <div class="error" v-if="submitted && !$v.form.mobile.maxLength">
                                    Mobile maximum length is 10
                                </div>
                                <div class="error" v-if="submitted && !$v.form.mobile.required">
                                    Mobile is required
                                </div>

                                <div class="country-code">
                                    <vue-country-code :enabledCountryCode="true" defaultCountry='India' @onSelect="onSelect">
                                    </vue-country-code>
                                </div>
                            </b-col>
                            <!-- Mobile  -->

                            <b-col md="12" class="mb-4">
                                <b-form-group id="input-group-1" label="Password:" label-for="input-3">

                                    <b-form-input id="input-3" v-model="form.password" :type="hidden_p ? 'password': 'text'" placeholder="Enter Password">
                                    </b-form-input>
                                    <span class="hide-show" @click="hidden_p = !hidden_p" v-html="hidden_p ? hide_password_p:show_password_p"></span>
                                </b-form-group>
                                <div class="error" v-if="submitted && !$v.form.password.required">
                                    Password is required
                                </div>
                                <div class="error" v-if="submitted && !$v.form.password.minLength">
                                    Password minimum length is 8
                                </div>
                                <div class="error" v-if="submitted && !$v.form.password.maxLength">
                                    Password maximum length is 18
                                </div>
                            </b-col>
                            <!-- Password  -->

                            <b-col md="12" class="mb-4">
                                <b-form-group id="input-group-1" label="Confirm Password:" label-for="input-4">
                                    <b-form-input id="input-4" v-model="form.confirm_password" :type="hidden ? 'password': 'text'" placeholder="Re-enter Password">
                                    </b-form-input>
                                    <span class="hide-show" @click="hidden = !hidden" v-html="hidden ? hide_password:show_password"></span>
                                </b-form-group>
                                <div class="error" v-if="submitted && !$v.form.confirm_password.sameAsPassword">
                                    Passwords must be identical
                                </div>
                            </b-col>
                            <!--Confirm  Password  -->

                            <b-col md="12" class="mb-4">
                                <b-form-group id="input-group-1" label="Referral:" label-for="input-5">
                                    <b-form-input id="input-5" v-model="form.referral" type="text" placeholder="Enter Referral">
                                    </b-form-input>
                                </b-form-group>
                                <div class="error" v-if="submitted && !$v.form.referral.minLength">
                                    Referral minimum length is 10
                                </div>
                                <div class="error" v-if="submitted && !$v.form.referral.maxLength">
                                    Referral maximum length is 10
                                </div>
                            </b-col>
                            <!-- Referral  -->

                            <b-col md="12" class="mb-4 text-center">
                                <b-button type="submit" class="col-md-2" variant="primary">Submit</b-button>
                            </b-col>
                            <!-- Submit Button  -->
                        </b-row>
                    </b-form>
                </b-col>
            </b-row>
        </b-container>
    </Layout>
</div>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import VueCountryCode from "vue-country-code";
// import appConfig from "@/app.config";
// import Multiselect from "vue-multiselect";
import {
    required,
    email,
    minLength,
    maxLength,
    sameAs,
} from "vuelidate/lib/validators";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";

export default {
    // page: {
    //     title: "Add New User",
    //     meta: [{ name: "description", content: appConfig.description }],
    // },
    components: {
        Layout,
        // Multiselect,
        PageHeader,
        VueCountryCode
    },

    data() {
        return {
            title: "Add New User",
            items: [{
                    text: "Users",
                    href: "#",
                },
                {
                    text: "Add new User",
                    active: true,
                },
            ],
            hidden: true,
            hidden_p: true,
            hide_password: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" style="fill: #495057;transform: ;msFilter:;"><path d="M12 4.998c-1.836 0-3.356.389-4.617.971L3.707 2.293 2.293 3.707l3.315 3.316c-2.613 1.952-3.543 4.618-3.557 4.66l-.105.316.105.316C2.073 12.382 4.367 19 12 19c1.835 0 3.354-.389 4.615-.971l3.678 3.678 1.414-1.414-3.317-3.317c2.614-1.952 3.545-4.618 3.559-4.66l.105-.316-.105-.316c-.022-.068-2.316-6.686-9.949-6.686zM4.074 12c.103-.236.274-.586.521-.989l5.867 5.867C6.249 16.23 4.523 13.035 4.074 12zm9.247 4.907-7.48-7.481a8.138 8.138 0 0 1 1.188-.982l8.055 8.054a8.835 8.835 0 0 1-1.763.409zm3.648-1.352-1.541-1.541c.354-.596.572-1.28.572-2.015 0-.474-.099-.924-.255-1.349A.983.983 0 0 1 15 11a1 1 0 0 1-1-1c0-.439.288-.802.682-.936A3.97 3.97 0 0 0 12 7.999c-.735 0-1.419.218-2.015.572l-1.07-1.07A9.292 9.292 0 0 1 12 6.998c5.351 0 7.425 3.847 7.926 5a8.573 8.573 0 0 1-2.957 3.557z"></path></svg>',
            show_password: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" style="fill: #495057;transform: ;msFilter:;"><path d="M12 9a3.02 3.02 0 0 0-3 3c0 1.642 1.358 3 3 3 1.641 0 3-1.358 3-3 0-1.641-1.359-3-3-3z"></path><path d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 12c-5.351 0-7.424-3.846-7.926-5C4.578 10.842 6.652 7 12 7c5.351 0 7.424 3.846 7.926 5-.504 1.158-2.578 5-7.926 5z"></path></svg>',
            hide_password_p: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" style="fill: #495057;transform: ;msFilter:;"><path d="M12 4.998c-1.836 0-3.356.389-4.617.971L3.707 2.293 2.293 3.707l3.315 3.316c-2.613 1.952-3.543 4.618-3.557 4.66l-.105.316.105.316C2.073 12.382 4.367 19 12 19c1.835 0 3.354-.389 4.615-.971l3.678 3.678 1.414-1.414-3.317-3.317c2.614-1.952 3.545-4.618 3.559-4.66l.105-.316-.105-.316c-.022-.068-2.316-6.686-9.949-6.686zM4.074 12c.103-.236.274-.586.521-.989l5.867 5.867C6.249 16.23 4.523 13.035 4.074 12zm9.247 4.907-7.48-7.481a8.138 8.138 0 0 1 1.188-.982l8.055 8.054a8.835 8.835 0 0 1-1.763.409zm3.648-1.352-1.541-1.541c.354-.596.572-1.28.572-2.015 0-.474-.099-.924-.255-1.349A.983.983 0 0 1 15 11a1 1 0 0 1-1-1c0-.439.288-.802.682-.936A3.97 3.97 0 0 0 12 7.999c-.735 0-1.419.218-2.015.572l-1.07-1.07A9.292 9.292 0 0 1 12 6.998c5.351 0 7.425 3.847 7.926 5a8.573 8.573 0 0 1-2.957 3.557z"></path></svg>',
            show_password_p: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" style="fill: #495057;transform: ;msFilter:;"><path d="M12 9a3.02 3.02 0 0 0-3 3c0 1.642 1.358 3 3 3 1.641 0 3-1.358 3-3 0-1.641-1.359-3-3-3z"></path><path d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 12c-5.351 0-7.424-3.846-7.926-5C4.578 10.842 6.652 7 12 7c5.351 0 7.424 3.846 7.926 5-.504 1.158-2.578 5-7.926 5z"></path></svg>',
            country: {
                number: "91",
                code: "IN",
                name: "India"
            },
            slctTemp2: null,
            //slctTempOptions2: ["User","Sub Admin"],
            slctTempOptions2: [{
                    key: "user",
                    value: "User"
                },
                {
                    key: "sub_admin",
                    value: "Sub Admin"
                },
            ],
            submitted: false,
            form: {
                fname: "",
                lname: "",
                email: "",
                mobile: "",
                password: "",
                referral: "",
                role: "user",
                confirm_password: "",
            },
        };
    },
    mounted() {
        console.log(this.$route.params.type);
        this.form.role = this.$route.params.type == "users" ? "user" : "sub_admin";
        if (this.$route.params.type != "users") {
            this.title = "Add New Sub Admin";
            this.items = [];
            this.items.push({
                text: "Sub Admin",
                href: "/sub_admin/list",
            }, {
                text: "Add New Sub Admin",
                active: true,
            });
        }
    },
    validations: {
        form: {
            fname: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(20)
            },
            // lname: {
            //     required,
            // },
            email: {
                required,
                email,
                maxLength: maxLength(30)
            },
            mobile: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(10)
            },
            password: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(18)
            },
            referral: {
                minLength: minLength(10),
                maxLength: maxLength(10)
            },
            role: {
                required
            },
            confirm_password: {
                sameAsPassword: sameAs("password")
            },
        },
    },
    methods: {
        async addUser() {
            this.submitted = true;
            if (this.$v.$invalid) {
                return;
            }
            this.submitted = false;
            this.$set(this.form, "role", this.form.role);
            var url =
                this.$route.params.type == "users" ?
                "admin/users/create" :
                "admin/sub_admin/create";
            let form = {
                fname: this.form.fname,
                lname: this.form.lname,
                email: this.form.email,
                mobile: this.form.mobile,
                password: this.form.password,
                referral: this.form.referral,
                role: "user",
                confirm_password: this.form.confirm_password,
                country_code: this.country.number,
                country_name: this.country.name,
            };
            var result = await ApiClass.postRequest(url, true, form);

            let icon = result.data.status_text == "Success" ? "success" : "error";
            Swal.fire({
                position: "center",
                icon: icon,
                title: result.data.message,
                showConfirmButton: false,
                timer: 1000,
            });

            if (result.data.status_code == 1) {
                this.$route.params.type == "users" ?
                    this.$router.push({
                        name: "User List"
                    }) :
                    this.$router.push({
                        name: "Sub Admin List"
                    });
            }
        },

        onSelect({
            name,
            iso2,
            dialCode
        }) {
            this.country.name = name;
            this.country.number = dialCode;
            this.country.code = iso2;
            console.log(name, iso2, dialCode);
        },
    },
};
</script>

<style scoped>
.hide-show {
    position: absolute;
    top: 37px;
    right: 20px;
}

div#input-number-field {
    position: relative;
}

input#input-mobile {
    padding-left: 100px;
}

.country-code {
    position: absolute;
    top: 31px;
    left: 19px;
}

.country-code .vue-country-select {
    border: none;
}
</style>
